import React, { useEffect, useState } from "react";
import {ParallaxComponent} from '../small-components/parallax-component';
import "../css/main-page.css";
import Header from "../small-components/header";
//import { Spin } from "antd";
import { t } from "i18next";
import Footer from "../small-components/footer";
import vmz from "../images/vmz.jpg";
//import ServerComponent from "../big-components/servers";

interface Server {
    server_id: number;
    map: string;
    status: any;
    link: string;
    title: string;
    usersCount: any;
    users: any;
    maxplayers: number;
}


/**

                <div className='trolls-cards-canvas animation'>
                    {servers.length > 0 ? (
                        servers.map((server:any, index: number)=>(
                            <ServerComponent key={index} server={server} t={t}/>
                        ))
                    ): servers.length === 0 && loading ? (
                        <Spin spinning={loading}/>
                    ):(
                        <h1>Похоже произошла ошибка, либо сервера стырили :0</h1>
                    )}
                </div>

*/


const MainPage: React.FC = () => {
    const [servers, setServers] = useState<Server[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const webSocket = new WebSocket('wss://scpubtroll.net:3002');

        webSocket.onmessage = (event) => {
            if(event.data !== "not known ap_type"){
            const res = JSON.parse(event.data);
            switch (res.res_type)
            {
                case "servers":
                    setServers(res.res_data);
                    setLoading(false);
                    break;
                }
            }
        };
        return () => {
            webSocket.close();
        };
    },[]);
    

    return(
        <div className="canvas-main-page">
            <div className="welcome-title animation-opacity">
                <div className="parallax-blur">
                    <ParallaxComponent 
                        title=""
                        description=""
                        speed={15}
                        aspectRatio={16/4.16}
                        id=""
                        image={vmz}
                    />
                </div>
                <div className="gradient-overlay" id="main-page-gradient"><div className="transition animation" id="main-page-wlc-text"><Header title={t('pages.main-page.wlc-to-site')} description={t('pages.main-page.wlc-to-site-desc')}/></div></div>
            </div>
            
            <div className="main-content">
                <div className="backround-msg animation"><div className="transition"><Header title={t('pages.main-page.who-are-we')}/></div></div>
                <div className="scpub-description animation">
                    <div className="scpub-desc-element">
                        <img className="scpub-desc-image" src="https://src.scpubtroll.net/images/logos/scpublogocat.png" alt="aga" />
                        <p>{t('pages.main-page.short-desc')}</p>
                    </div>
                </div>
            </div>
            <Footer description="SC-Pub Site. Version 0.5.0, made by Andrei (Coelho_)"/>
        </div>
    );
}

export default MainPage;