import { Spin, Table } from "antd";
import Search from "antd/es/input/Search";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { api } from "../const";

import "../css/playersp.css"
import Footer from "../small-components/footer";
import Header from "../small-components/header";
import { ParallaxComponent } from "../small-components/parallax-component";

const PlayersPage = () => {
    const [data, setData] = useState();
    const [spinning, setSpinning] = useState<boolean>(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    const columns = [
        {
          title: '#',
          dataIndex: 'avatar',
          width: 30,
          render: (avatar: any) => (
            <img
              src={avatar}
              alt='Avatar'
              style={{ width: '30px', height: '30px', borderRadius: '100%' }}
            />
          )
        },
        {
          title: t('ud.nickname'),
          dataIndex: 'nickname',
          width: 150,
        },
        {
          title: 'Steam ID',
          dataIndex: 'steamid',
          width: 150,
        },
        {
          title: t('move-to-profile'),
          dataIndex: 'steamid',
          width: 150,
          render: (steamid: any) => (
            <a href={"https://scpubtroll.net/profile?steamid="+steamid}>{t('move-to-profile')}</a>
          )
        }
    ];

    async function fetchUsers () {
        try{
          setSpinning(true);
          // Making response to get players' list
          const res = await api.get('/users/');
          const resData = res.data;
  
          // Setting data
          setData(resData);
          setSpinning(false);
        } catch(err) {
          console.log(err);
          setSpinning(false);
        }
    }

    const onSearch = async (value: string) => {
        try{
          const link = '/api/public/searchPlayers/?id='+value;
          const res = await api.get(link);
          const resData = res.data;
  
          setData(resData);
        } catch(err){
          console.error(err);
        }
    }

    return(
        <>
            <div className="welcome-title" style={{height: 400}}>
                <ParallaxComponent 
                    title={""}
                    description={""}
                    speed={10}
                    aspectRatio={16/4.16}
                    id={""}
                />
                <div className="gradient-overlay" id="players-page-gradient"><Header title={t('wlc-to-page.players')} description={t('wlc-to-page.players-desc')}/></div>
            </div>
            <div></div>
            <div className="table-block">
              
                <div className="table">
                    <Search
                        placeholder="steamid/id"
                        allowClear
                        onSearch={onSearch}
                        style={{
                            width: '70%',
                            marginLeft: '15%',
                            marginRight: '15%',
                            marginBottom: '1%',
                            marginTop: '1%',
                        }}
                    />
                      <Table
                          columns={columns}
                          dataSource={data}
                          pagination={{
                              pageSize: 8,
                          }}
                          scroll={{
                              y: 500,
                          }}
                          style={{marginLeft: '15%', marginRight: '15%', width: '70%', marginBottom: '1%'}}
                          loading={spinning}
                      ></Table>
                </div>
              <Footer description="SC-Pub site. Версия 0.5.0, выполнил и сделал Андрей (Coelho_)"/>
            </div>
        </>
    )
};

export default PlayersPage;