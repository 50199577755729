import React from "react";

interface LayoutProps {
    title: string;
    description?: string;
}

const Header: React.FC<LayoutProps> = ({ title, description }) => {
    return(
        <>
            <div className="Title">
                <h1>{title}</h1>
                <p>{description}</p>
            </div>
        </>
    )
}


export default Header;