import axios from "axios";
import config from "./config.json";

export const url = config.cfg.local ? config.links.locallink : config.links.publiclink;

export interface User {
  nickname: string,
  steamid: string,
  avatar: string,
  roles: {
      serverrole: string,
      siteroles: string[]
  },
  reverse_id: number,
  server_id: number,
  about: string,
  link: string,
}

export const api = axios.create({
    baseURL: url+'/', // change to your desired hostname and port
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
});

export interface t {
  tr: any;
}