import React from "react";
import Header from "./header";

import { Parallax, ParallaxBanner, ParallaxBannerLayer, ParallaxProvider } from "react-scroll-parallax";


// Setting interface props
interface ParallaxProps {
    title: any;
    aspectRatio: number;
    speed: number;
    description?: string;
    image?: any;
    id: string;
}

export const ParallaxComponent: React.FC<ParallaxProps> = ({title, description, aspectRatio, speed, image, id}) => {
    return(
        <>
        <ParallaxProvider>
            <ParallaxBanner style={{aspectRatio: aspectRatio, height:'98%'}} className="ParallaxBanner">
                <ParallaxBannerLayer image={image} speed={speed} />
                <Parallax speed={-5}>
                    <div className="canvas" id="parallaxComponentCanvas">
                        <div className="header" id={id} style={{marginTop: "250px"}}>
                            <Header
                                title={title}
                                description={description}
                            />
                        </div>
                    </div>
                </Parallax>
            </ParallaxBanner>
        </ParallaxProvider>
        </>
    );
}