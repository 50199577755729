import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface LayoutProps {
  link: string;
  text?: string;
  className?: string;
  id?: string;
  onClick?: () => void;
  children?: ReactNode;
  linkClass?: string;
  outLink: boolean;
}

export const NavigationLink: React.FC<LayoutProps> = ({
  link,
  text,
  className,
  onClick,
  children,
  id,
  linkClass,
  outLink,
}) => {
  return (
    <div className={className} id={id}>
      {outLink ? (
        <a className={linkClass || "link"} href={link} onClick={onClick ?? undefined} >
          {children || text}
        </a> 
      ):(
        <Link className={linkClass || "link"} to={link} onClick={onClick ?? undefined} >
          {children || text}
        </Link> 
      )}
      
    </div>
  );
};
