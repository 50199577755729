import React, {useEffect, useState} from "react";
import dayjs, { Dayjs } from 'dayjs';
import { Button, Form, Input, DatePicker, Switch, notification } from "antd";
import _ from "lodash";

import { Stuff } from "../../types/interfaces";

import { Items, ItemsEdit } from "./collapse-items";
import { ModalElement } from "./modal";
import { api, crypt } from "../../../const";



const StuffModal: React.FC<{admin: Stuff}> = ({admin}) => {
    const [notiapi, contextHolder] = notification.useNotification();
    const [newAdminData, setNewAdminData] = useState<Stuff>(admin);
    const [editMode, setEditMode] = useState(false);
    const [open, setOpen] = useState(false);
    const [openedTab, setOpenedTab] = useState(0);

    const sendNewData = async() => {
        try {
            const encryptedData = crypt.Encrypt(JSON.stringify(newAdminData));
            api.post('/stuff/change/?steamid='+admin.steamid, {data: encryptedData})
            .then(function (res) {
                if(res.status == 200)
                {
                    notiapi.success({
                        message: 'Данные отправлены! =D',
                        description: 'Данные об '+ admin.nickname +' успешно отправлены!',
                        showProgress: true,
                        pauseOnHover: true,
                    })
                }
            })
            .catch(function (err) {
                if (err.response) {
                    notiapi.error({
                        message: 'Получена ошибка! =(',
                        description: 'Мы попытались отправить твой запрос, но получили следующую ошибку от сервера. Напиши своему руководителю о ней!\n'+err.response.data,
                        showProgress: true,
                        pauseOnHover: true,
                    })
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    return(
        <>{contextHolder}
        <ModalElement  
            open={{open: open, setOpen: setOpen}}
            header={`${admin.name ? admin.name : admin.steamid} (${admin.nickname}${admin.name ? ', ' + admin.steamid : ''})`}
            footer={editMode ?
                <Button onClick={sendNewData} type="primary">
                    Подтвердить
                </Button>
                :
                <Button onClick={() => setOpen(false)}>Закрыть</Button>
            }
        >
            
            <div style={{display: 'flex', width: '100%'}}>
                <span style={{width: '50%'}}>
                    <h4 style={{margin: '7px 0 2px 0'}}>{admin.stuffData.managerAndDate?.manager.nickname}</h4>
                    <h5 style={{margin: '2px 0 7px 0'}}>{admin.stuffData.managerAndDate?.date ? new Date(admin.stuffData.managerAndDate?.date).toLocaleString('ru-ru',{day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'}) : "Изменений не было / Новый сотрудник"}</h5>
                </span>
                <span style={{width: '50%', justifyContent: 'end', alignItems: 'center', display: 'flex', paddingRight: '7px'}}>
                    <p style={{margin: '5px'}}>Режим редактирования</p>
                    <Switch checkedChildren="Вкл." unCheckedChildren="Выкл." defaultChecked={editMode} onChange={(checked) => setEditMode(checked)}/>
                </span>
            </div>
            {editMode ?
                <Form
                    name="StuffModal"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 750 }}
                    onFinish={sendNewData}
                    autoComplete="off"
                >
                    <ItemsEdit newAdminData={newAdminData} setNewAdminData={setNewAdminData}/>
                </Form>
            :
                <Items admin={admin}/>
            }
        </ModalElement>
        </>
    )
}

const StuffItems: React.FC<{admins: Stuff[]}> = ({admins}) => {
    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
    const [roles, setRoles] = useState([{
        name: '',
        access: [''],
        id: 100,
        admin: false,
    }]);

    const fetchRoles = async () => {
        try {
            const resData = (await api.get('/api/admin/roles')).data;
            if (resData) {
                resData.sort((a:any, b:any) => a.id - b.id)
                setOptions(resData.map((role: any) => ({
                    value: role.name,
                    label: role.name,
                })));
                setRoles(resData);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {fetchRoles();},[admins]);

    const getItems = (newAdminData: Stuff) => {
        const adminData: Stuff = {
            ...newAdminData, stuffData: {
                managerAndDate: {
                    manager: {
                        nickname: '',
                        steamid: '',
                    },
                    date: ''
                },
                personalInfo: {
                    age: 0,
                    name: '',
                    discord: '',
                    telegram: ''
                },
                projectWork: {
                    departments: [],
                    positions: [],
                },
                workHistory: {
                    departments: [],
                    positions: [],
                },
                about: '',
                bestQualities: '',
                notes: '',
                reprimands: [],
                thanks: [],
            }
        }
        return adminData;
    }

    
    return(
        <>
            <div style={{display: 'flex', overflow: 'auto', maxHeight: '60vh', padding: '15px'}}>
                <table className="roles-table admins-table table">
                    <thead>
                        <tr>
                            <td rowSpan={2}>#</td>
                            <td colSpan={2}><b>Информация</b></td>
                            <td colSpan={2}><b>Роли</b></td>
                            <td rowSpan={2}><b>Действия</b></td>
                        </tr>
                        <tr>
                            <td><b>Никнейм</b></td>
                            <td><b>SteamID</b></td>
                            <td><b>Роль сервера</b></td>
                            <td><b>Роли сайта</b></td>
                        </tr>
                    </thead>
                    <tbody>
                        {admins.map((user: any, index: number)=>{
                            let admin = user;
                            if(user.stuffData){
                                console.log(user);
                            } else {
                                admin = getItems(user);
                            }

                            return <tr key={index}>
                                <td>{++index}</td>
                                <td>{user.nickname}</td>
                                <td>{user.steamid}</td>
                                <td>{user.roles.serverrole}</td>
                                <td>{user.roles.siteroles.map((role: any, index: any, arr: any)=> {return index !== arr.length -1 ? role+', ' : role})}</td>
                                <td><StuffModal admin={admin}/></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <span style={{width: '100%', textAlign: 'end', marginTop: '10px'}}><b>Всего персонала:</b> {admins.length === 0 ? "Считается..." : admins.length}</span>
        </>
    );
}

export default StuffItems;
