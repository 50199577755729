import React, { useState, useEffect } from 'react';
import { Drawer, MenuProps, Dropdown, } from 'antd';
import {
  GlobalOutlined,
} from '@ant-design/icons';

import './navbar.css';
import { NavigationLink } from './navlink';
import { useTranslation } from 'react-i18next';
import { i18n } from '../i18n/i18n';
import { api, url } from '../../../const';

interface aboba {
  ud?: any;
}

const Navbar: React.FC<aboba> = ({ud}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const { t } = useTranslation();

  // Функция для обработки прокрутки страницы
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 10) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const settingWidth = () => {
      if(width != window.innerWidth)
      {
        setWidth(innerWidth);
      }
    }

    const widthInterval = setInterval(settingWidth, 500);

    return () => {
      clearInterval(widthInterval);
    }
  }, []);

  const LogOut = async () => {
    try{
      await api.get('/logout');
      window.location.href = '/';
    } catch(err){
      console.log(err);
    }
  };

  const changeLanguage = async (lng: string, lng_full: string) => {
    try{
      i18n.changeLanguage(lng);
      await api.post('/api/profile/language', {lng: lng});
    } catch(err){
      console.log(err);
    }
  }

  const openCloseMenu = () => {setOpen(!open)};

  const DropdownComponent = () => {
    const items: MenuProps['items'] = [
      {
        label: "Русский",
        key: '0',
        onClick: () => changeLanguage("ru","Русский"),
      },
      {
        label: "English",
        key: '1',
        onClick: () => changeLanguage("en","English"),
      }
    ]

    return(
      <Dropdown menu={{items}} placement="bottomRight" arrow={{ pointAtCenter: true }}>
        <GlobalOutlined style={{color: 'white'}} />
      </Dropdown>
    )
  }
  
  const items: MenuProps['items'] = [
    {
      label: ud ? (
        <a className='dropdown-link-box' href={`/profile?steamid=${ud.steamid}`} >
          <img className='dropdown-items' src={ud.avatar} height="15px" width="15px" style={{borderRadius: '25px', marginRight: '3px'}} alt="avatar"></img>
          {t('navbar.profile')}
        </a>
      ) : "",
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: t('leave'),
      key: '2',
      onClick: LogOut,
      danger: true,
    },
  ];

  return (
    <div className='navbar-box'>
      <div className={isScrolled ? 'navbar scrolled' : 'navbar'}>
        {width < 600 ? (
          <div className="navbar-links">
            <div className='main-nav-link'>
              <img alt='example' width={40} src='https://src.scpubtroll.net/images/logos/scpublogoround.png'/>
              <NavigationLink outLink={false} link="/"><h2><b>SCPub</b></h2></NavigationLink>
            </div>
            <div className="nav-links">
              <a className='link' onClick={openCloseMenu}>{t('more')}</a>
              <Drawer
                title={t('hiden-navbar')}
                placement='bottom'
                closable={true}
                onClose={openCloseMenu}
                open={open}
                height={200}
              >
                <div className='nav-links-mobile'>
                  <NavigationLink outLink={false} linkClass="hiden-links" link="/teachers" text={t('navbar.teachers')}/>
                  {ud && ud.nickname.length > 0 ? (
                    <Dropdown menu={{items}}>
                      <span style={{alignItems: 'center', display: 'flex', cursor: 'pointer'}}><p style={{margin: 0, marginRight: '5px'}}></p><img src={ud.avatar} height="30px" width="30px" style={{borderRadius: '25px'}} alt="avatar"></img></span>
                    </Dropdown>
                  ):(
                    <NavigationLink outLink={true} linkClass="hiden-links" link={url+"/api/auth/steam"} text={t('move-to-steam')}/>
                  )}
                  <DropdownComponent/>
                </div>
              </Drawer>
            </div>
          </div>
        ):(
          <div className='navbar-links'>
          <div className='main-nav-link'>
            <img alt='example' width={40} src='https://src.scpubtroll.net/images/logos/scpublogoround.png'/>
            <NavigationLink outLink={false} link="/" text=''><h2><b>SCPub</b></h2></NavigationLink>
          </div>
          <div className="nav-links">
            <NavigationLink outLink={false} link="/teachers" text={t('navbar.teachers')}/>
            {ud && ud.nickname.length > 0 ? (
              <Dropdown menu={{items}}>
                <span style={{alignItems: 'center', display: 'flex', cursor: 'pointer'}}><p style={{margin: 0, marginRight: '5px'}}></p><img src={ud.avatar} height="30px" width="30px" style={{borderRadius: '25px'}} alt="avatar"></img></span>
              </Dropdown>
            ):(
              <NavigationLink outLink={true} link={url+"/auth/steam"} text={t('move-to-steam')}/>
            )}
            <DropdownComponent/>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

export {Navbar};