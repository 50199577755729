import React from "react";
import "../css/footer.css"

interface LayoutProps {
    title?: string;
    description?: string;
}

const Footer: React.FC<LayoutProps> = ({ title, description }) => {
    return(
        <>
            <footer className="footer animation-opacity">
                <h1>{title}</h1>
                <div className="footerTextContainer">
                    <span className="footerText">{description}</span>
                </div>
            </footer>
        </>
    )
}


export default Footer;