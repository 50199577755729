import React, { useState } from "react";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";

import "../../css/pages/reg-box.css";

const { t } = useTranslation();

interface RegForm {
  email: string;
  password: string;
  nickname: string;
}

const RegistrationPage = () => {
  const [regform, setRegform] = useState<RegForm>()

  return(
    <div className="auth-page">
      <div className="auth-box">
        <h1>{t('registration')}</h1>
        <Divider />
      </div>
    </div>
  )
}

export default RegistrationPage;
