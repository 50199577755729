import React, { useState, useEffect } from 'react';
import { Drawer, MenuProps, Dropdown, } from 'antd';

import './navbar.css';
import '../../css/navigation-link.css';
import { NavigationLink } from './navlink';
import { useTranslation } from 'react-i18next';
import { url } from '../../const';
import { i18n } from '../i18n/i18n';

interface aboba {
  ud?: any;
  api: any;
  langText: string;
  setLangText: any;
}

const Navbar: React.FC<aboba> = ({ud, api, langText, setLangText}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [logged, setLogged] = useState(false);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState<number>(0)

  const LogOut = async () => {
    try{
      await api.get('/api/auth/logout');
      window.location.href = '/';
    } catch(err){
      console.log(err);
    }
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <a className='dropdown-link-box' href={`/profile?steamid=${ud.steamid}`} >
          <img className='dropdown-items' src={ud.avatar} height="15px" width="15px" style={{borderRadius: '25px', marginRight: '3px'}} alt="avatar"></img>
          {t('navbar.profile')}
        </a>
      ),
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: t('leave'),
      key: '2',
      onClick: LogOut,
      danger: true,
    },
  ];

  const openCloseMenu = () => {
    if(open){
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  // Функция для обработки прокрутки страницы
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 10) { // Если прокрутка больше 50 пикселей, меняем состояние isScrolled на true
      setIsScrolled(true);
    } else { // Иначе, возвращаем навбар в исходное состояние
      setIsScrolled(false);
    }
  };

  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll); // Добавляем обработчик события scroll при монтировании компонента
    return () => {
      window.removeEventListener('scroll', handleScroll); // Убираем обработчик события scroll при размонтировании компонента
    };
  }, []);

  useEffect(() => {
    const settingWidth = () => {
      if(window.innerWidth !== width)
      {
        setWidth(window.innerWidth);
      }
    }

    const settingLog = async () => {
      if(ud.nickname !== ""){
        setLogged(false);
      } else {
        setLogged(true);
      }
    }

    const timeHooks = () => {
      settingLog();
      settingWidth();
    }

    const widthChangeHook = setInterval(timeHooks, 500);

    return () => {
      clearInterval(widthChangeHook);
    }
  },[])

  const changeLanguage = async (lng: string, lng_full: string) => {
    try{
      i18n.changeLanguage(lng);
      setLangText(lng_full)
      await api.post('/api/profile/language', {lng: lng});
    } catch(err){
      console.log(err);
    }
  }

  const DropdownComponent = () => {
    const items: MenuProps['items'] = [
      {
        label: "Русский",
        key: '0',
        onClick: () => changeLanguage("ru","Русский"),
      },
      {
        label: "English",
        key: '1',
        onClick: () => changeLanguage("en","English"),
      }
    ]

    return(
      <Dropdown menu={{items}} placement="bottomRight" arrow={{ pointAtCenter: true }}>
        <p className={width < 600 ? "hiden-links" : "link"}>{langText}</p>
      </Dropdown>
    )
  }

  return (
    <div className='navbar-box'>
      <div className={isScrolled ? 'navbar scrolled' : 'navbar'}>
        {width < 600 ? (
          <div className="navbar-links">
            <div className='main-nav-link'>
              <img alt='example' width={40} src='https://src.scpubtroll.net/images/logos/scpublogoround.png'/>
              <NavigationLink outLink={false} link="/"><h2><b>SCPub</b></h2></NavigationLink>
            </div>
            <div className="nav-links">
              <a className='link' onClick={openCloseMenu}>{t('more')}</a>
              <Drawer
                title={t('hiden-navbar')}
                placement='bottom'
                closable={true}
                onClose={openCloseMenu}
                open={open}
                height={200}
              >
                <div className='nav-links-mobile'>
                  <NavigationLink outLink={false} linkClass="hiden-links" link="/rules" text={t('navbar.rules')}/>
                  <NavigationLink outLink={false} linkClass="hiden-links" link="/players" text={t('navbar.players')}/>
                  {logged && ud.nickname ? (
                    <Dropdown menu={{items}}>
                      <span style={{alignItems: 'center', display: 'flex', cursor: 'pointer'}}><p style={{margin: 0, marginRight: '5px'}}></p><img src={ud.avatar} height="30px" width="30px" style={{borderRadius: '25px'}} alt="avatar"></img></span>
                    </Dropdown>
                  ):(
                    <NavigationLink outLink={true} linkClass="hiden-links" link={url+"/api/auth/steam"} text={t('move-to-steam')}/>
                  )}
                  <DropdownComponent/>
                </div>
              </Drawer>
            </div>
          </div>
        ):(
        <div className='navbar-links'>
          <div className='main-nav-link'>
            <img alt='example' width={40} src='https://src.scpubtroll.net/images/logos/scpublogoround.png'/>
            <NavigationLink outLink={false} link="/" text=''><h2><b>SCPub</b></h2></NavigationLink>
          </div>
          <div className="nav-links">
            <NavigationLink outLink={false} link="/players" text={t('navbar.players')}/>
            <NavigationLink outLink={false} link="/rules" text={t('navbar.rules')}/>
            {logged && ud.nickname ? (
              <Dropdown menu={{items}}>
                <span style={{alignItems: 'center', display: 'flex', cursor: 'pointer'}}><p style={{margin: 0, marginRight: '5px'}}></p><img src={ud.avatar} height="30px" width="30px" style={{borderRadius: '25px'}} alt="avatar"></img></span>
              </Dropdown>
            ):(
              <NavigationLink outLink={true} link={url+"/auth/steam"} text={t('move-to-steam')}/>
            )}
            <DropdownComponent/>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

export {Navbar};