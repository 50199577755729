import React from "react";
import { Route, Routes } from "react-router-dom";

import MainPage from "../pages/main-page";
import ProfilePage from "../pages/player-page";
import PlayersPage from "../pages/players";

interface props {
    userData: any;
    t: any;
}

export const RoutesRouter: React.FC<props> = ({userData, t}) => {
    return(
        <>
        <Routes>
            <Route path="/" element={<MainPage/>} />
            <Route path="/profile" element={<ProfilePage/>}/>
            <Route path="/lk" element={<ProfilePage/>}/>
            <Route path="/players" element={<PlayersPage/>}/>
        </Routes>
        </>
    );
}