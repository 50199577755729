import React, { useEffect, useState } from "react";
import { RoutesRouter } from "./router";
import { useTranslation } from 'react-i18next';
import {Navbar} from "../big-components/navbar/navbar";


import "../css/canvas.css";
import "../css/app.css";

import { api, User } from "../const";


const App: React.FC = () => {
    const [button, setButton] = useState(false);
    const [userData, setUserData] = useState<User>({
      nickname: "",
      about: "",
      roles: {serverrole: '', siteroles: [""]},
      avatar: "",
      link: "",
      steamid: "",
      server_id: 0,
      reverse_id: 0
    })
    const { t, i18n } = useTranslation();
    const [lng, setLng] = useState(i18n.language);

    useEffect(() => {
        settingWidthHeight();
        fetchUD();
    }, []);

    const fetchUD = async () => {
      try{
        const response = await api.get('/auth/');
        const resdata = response.data;

        if(response.status !== 204)
        setUserData({
          nickname: resdata.nickname,
          about: resdata.about,
          roles: resdata.roles,
          avatar: resdata.avatar,
          link: resdata.link,
          steamid: resdata.steamid,
          server_id: resdata.server_id,
          reverse_id: resdata.reverse_id
        });

        i18n.changeLanguage(resdata.lng);
        switch (resdata.lng){
          case "ru":
            setLng("Русский");
            break;
          case "en":
            setLng("English");
            break;
        }
      } catch(error: any) {
        if (error.response && error.response.status === 404) {
        } else {
          console.warn('Ошибка при получении данных:', error);
        }
      }
    };

    function settingWidthHeight () {
        const width = window.screen.width;
    
        if(width >= 800){
          setButton(false);
        } else {
          setButton(true);
        }
      }

    return(
        <div className="app">
          <Navbar langText={lng} setLangText={setLng} ud={userData} api={api}/>
          <RoutesRouter userData={userData} t={t}/>
        </div>
    );
}

export default App
