import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localization from './languages.json';

const localStorageKey = 'language';

const getSavedLanguage = () => localStorage.getItem(localStorageKey);

const saveLanguage = (lng: any) => {
  localStorage.setItem(localStorageKey, lng);
};

const resources = {
  fi: localization.fi,
  en: localization.en
};

const userLanguage = getSavedLanguage() || 'ru';

i18n.use(initReactI18next).init({
  resources,
  lng: userLanguage, // язык по умолчанию
  debug: false,
  interpolation: {
    escapeValue: false, // не экранировать спец. символы внутри строки
  },
});

export {i18n, saveLanguage};
