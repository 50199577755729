import React, { useEffect, useState } from 'react';
import './App.css';
import { Navbar } from './components/big/navbar/navbar';
import { api } from './const';
import { RoutesRouter } from './router';

interface User {
  email: string,
  names: {
    nickname: string;
    fullname: string | undefined;
  };
  role: string;
  created_at: string;
  uuid: string;
}

const App = () => {
  const [ud, sud] = useState<User>(); // UD - user's data, SUD - set user's data

  useEffect(() => {
    const fetchData = async () => {
      const userdata = await api.get('/');
      sud(userdata.data)
    }
    fetchData();
  },[api]);

  return (
    <div className="App">
      <Navbar ud={ud}/>
      <RoutesRouter />
    </div>
  );
}

export default App;
