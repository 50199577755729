import React, { useEffect, useState } from "react";
import { Button, Carousel, CollapseProps, ConfigProvider, List, Modal } from 'antd';
import { api, mask } from "../../const";
import { t } from "i18next";

const NewsModal: React.FC<{annon: {title: string, description: string, author: {steamid: string, nickname: string}, date: number, photos: string[]}}> = ({annon}) => {
    const [open, setOpen] = useState(false);
    return(
        <ConfigProvider
        theme={{
            token: {
                colorBorder: '#d9d9d9',
                colorText: "black",
                colorTextDescription: "black",
                fontFamily: "'Comfortaa', serif",
            }
        }}
        >
            <Button type="default" onClick={() => setOpen(true)}>{t('open')}</Button>
            <Modal
                title={annon.title}
                centered
                footer={[<Button onClick={() => setOpen(false)}>{t('close')}</Button>]}
                open={open}
                onCancel={() => setOpen(false)}
                styles={mask}
            >
                <div style={{overflowY: "auto", maxHeight: '600px', padding: '10px 5px', width: '95%', margin: "auto"}}>
                    {annon.description}
                    <div style={{marginTop: 20}}>
                        
                        {annon.photos && annon.photos.length > 0 ?
                         annon.photos.length > 1 ?
                        <Carousel autoplay>
                            {annon.photos.map((img: string, index: number) => <img style={{borderRadius: '7px'}} width="100%" key={index} src={img} alt="aboba" />)}
                        </Carousel>
                            : <img style={{borderRadius: '5px'}} width="100%" src={annon.photos[0]} alt="aboba" /> : <span></span>
                        }
                    </div>
                    <p style={{ color: "rgba(0,0,0,0.3)", width: '97%', textAlign: 'end' }}>{`${new Date(annon.date * 1000).toLocaleDateString("ru-RU",{day: 'numeric', month: 'numeric', year: 'numeric'})}`} • {annon.author.nickname}</p>
                </div>
            </Modal>
        </ConfigProvider>
    )
}

const News: React.FC = () => {
    const [news, setNews] = useState([]);

    const fetchNews = async () => {
        try {
            const data = (await (api.get("/news/all"))).data;
            setNews(data);
        } catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchNews();
    },[]);

    const checkTextLength = (text: string) => {
        const symbols = text.split('');
        if(symbols.length > 30)
        {
            let textarray = []
            for(let i = 0; i < 60; i++)
            {
                textarray.push(symbols[i]);
            }
            textarray.push(" ...");
            return textarray.join('');
        } else {
            return text
        }
    };

    const data = news.map((annon: any) => {
        return {
            title: annon.header,
            description: annon.description,
            author: annon.author,
            date: annon.date,
            photos: annon.photos
        }
    });

    return(
        <ConfigProvider
        theme={{
            components: {
                Collapse: {
                    headerBg: 'rgba(0,0,0,0.2)'
                },
            },
            token: {
                colorBorder: 'rgb(50,50,50)',
                colorTextDescription: "rgb(88, 88, 88)",
                fontFamily: "'Comfortaa', serif",
                colorSplit: "rgba(255,255,255,0.6)"
            }
        }}
        >
            {/*<Collapse bordered={false} items={items} defaultActiveKey={['0']} />*/}
            <List
                itemLayout="horizontal"
                dataSource={data}
                style={{width: '50%', margin: 'auto', overflow: 'auto', maxHeight: '75vh'}}
                renderItem={(item, index) => (
                <List.Item
                    actions={[<NewsModal annon={item}/>]}
                >
                    <List.Item.Meta
                        key={index}
                        title={<b>{item.title}</b>}
                        description={checkTextLength(item.description)}
                    />
                </List.Item>
                )}
            />
        </ConfigProvider>
    )
}

export default News;