import React, { useEffect, useState } from "react";
import "../css/summary.css";
import { api, crypt } from "../const";
import { Layout, notification, theme, Collapse, CollapseProps, Tooltip, Divider, Card, Avatar, Modal, Button } from "antd";
import { FullscreenOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";

import config from "../config.json";


type GroupedDataType = {
  [key: string]: { connect_time: number; server_id: number; summary: number }[];
};

const href = config.cfg.local ? "https://db.scpubtroll.net/" : "https://admin.scpubtroll.net/";

function gettingTime (time: any) {
  const newTime = new Date(time * 1000);

  const d = { // Creating data of summary time from last time, named D
    minutes: newTime.getMinutes(),
    seconds: newTime.getSeconds(),
  }
  return `${Math.floor(time / 3600)}:${d.minutes.toString().padStart(2,'0')}:${d.seconds.toString().padStart(2,'0')}`
}

function gettingDate(date: any){
  date = new Date(date * 1000);
  const time = `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`
  const dd = {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      date: date.getDate(),
      time,
  }
  return dd.date+'.'+dd.month+'.'+dd.year+', '+dd.time;
}


const OnlineData: React.FC <{admin: any}> = ({admin}) => {
  const [groupedData, setGroupedData] = useState<GroupedDataType>({});
  const timeZone = 'Europe/Moscow';

  // Группировка данных по годам
  useEffect(() => {
    const groupByYear = (data: any[]) => {
      return data.reverse().reduce((acc: { [key: string]: any[] }, item) => {
        const date = new Date(item.connect_time * 1000);
        const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`; // Формат "Год-Месяц"
    
        if (!acc[yearMonth]) {
          acc[yearMonth] = [];
        }
    
        acc[yearMonth].push(item);
        return acc;
      }, {});
    };
    
    if(admin && admin.connect_time){
      setGroupedData(groupByYear(admin.connect_time));
    }
  }, [admin,]);

  const items: CollapseProps['items'] = Object.keys(groupedData)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime()) // Сортировка по убыванию дат (год-месяц)
    .map((yearMonth: string, index: number) => {
      return {
        key: index,
        label: (
          <div style={{ display: 'flex' }}>
            <h4 style={{ margin: '5px 10px 5px 10px' }}>{yearMonth}</h4>
            <h4 style={{ margin: '5px 10px 5px 10px' }}>{`${Math.floor(groupedData[yearMonth].reduce((summary: number, item) => ((summary + item.summary)), 0) / 60 / 60)} ч. и ${groupedData[yearMonth].length} раз заходил(-а)`}</h4>
          </div>
        ),
        children: (
          <div className="online-table-box">
            <table className="online-table" >
              <thead>
                <tr>
                  <td rowSpan={2}><h3>Сервер, на который заходил</h3></td>
                  <td colSpan={3}><h3>Временные данные</h3></td>
                </tr>
                <tr>
                  <td><h3>Время захода</h3></td>
                  <td><h3>Время выхода</h3></td>
                  <td><h3>Суммарное проведённое время</h3></td>
                </tr>
              </thead>
              <tbody>
                {groupedData[yearMonth] ? 
                  groupedData[yearMonth].map((row: any, idx: number, arr: any) => 
                    <tr key={idx}>
                      <td className="column">{row.server_id}</td>
                      <td>
                        <Tooltip title={`${new Date((row.connect_time) * 1000).toLocaleString('ru-RU', {
                            timeZone: timeZone,
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false
                          })}`}>
                          {`${gettingDate(row.connect_time)}`}
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip title={`${new Date((row.connect_time + row.summary) * 1000).toLocaleString('ru-RU', {
                          timeZone: timeZone,
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                          hour12: false
                        })}`}>
                          {row.server_id === admin.server_id && idx === 0 ? 'На сервере' : `${gettingDate(row.connect_time + row.summary)}`}
                        </Tooltip>
                      </td>
                      <td className="column">{`${gettingTime(row.summary)}`}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td>Нету данных</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        )
      };
    });

  
  return(
    <Collapse items={items} />
  )
}

const AvatarTitle: React.FC<{admin: any}> = ({admin}) => {
  return <span><Avatar style={{marginRight: 15}} src={admin.avatar} alt="avatar"/>{admin.nickname}</span>
};

const OnlineDataModal: React.FC<{open: boolean, setOpen: any, admin: any, data: any}> = ({open, setOpen, admin, data}) => {
  return(
    <Modal
      open={open}
      title={[<AvatarTitle admin={admin}/>, <Divider />]}
      footer={[
        <Button onClick={() => setOpen(false)}>Закрыть</Button>
      ]}
      centered
      onCancel={() => setOpen(false)}
      height="600px"
      width="auto"
    >
      <div></div>
      <div className="admin-modal-connecttime">
        <OnlineData admin={admin} />
      </div>
    </Modal>
  )
}

const AdminCard: React.FC<{admin: any, data: any}> = ({admin, data}) => {
  const [open, setOpen] = useState(false);
  return(
    <Card className="admin-card"
      actions={admin.connect_time ? [<FullscreenOutlined onClick={() => setOpen(true)}/>] : [<p>Без онлайна</p>]}
      title={<AvatarTitle admin={admin}/>}
      bordered={false}
      hoverable
    >
      <p>
        <b>SteamID: </b>
        <a
          target="_blank"
          href={admin.link}
        >
          {admin.steamid}
        </a></p>
      <p><b>Роли сайта: </b>{admin.roles.siteroles.map((role: any, index: number, arr: any) => index < arr.length - 1 ? `${role}, ` : role)}</p>
      <p><b>Реверсивка: </b>{admin.reverse_id}</p>
      <p><b>О себе: </b>{admin.about ? admin.about : "Отсутствует"}</p>
      <p>Сейчас {admin.server_id && admin.server_id !== 0 ? "на " + admin.server_id + "-м сервере" : "не играет ни на каком сервере"}</p>
      <OnlineDataModal open={open} setOpen={setOpen} data={data} admin={admin}/>
    </Card>
  )
}

const OnlinePage: React.FC<{data: any}> = ({data}) => {
    const [adminData, setAdminData] = useState([]);
    const [apiNot, contextHolder] = notification.useNotification();
    const {token: { borderRadiusLG },} = theme.useToken();
    const height = window.innerHeight;

    useEffect(() => {
      const openNotification = () => {
        apiNot['warning']({
        message: 'Нету данных',
        description:
            'При получении данных возникла ошибка. Обнови страницу, либо если ошибка повторяется - напиши Coelho_',
        });
      }

      const fetchData = async () => {
        try {
          let res = (await api.get('/stuff/')).data;
          res = JSON.parse(crypt.Decrypt(res));
          if(res){
            res.sort((a:any, b:any) => (a.server_id - b.server_id))
            setAdminData(res);
          } else {
            openNotification();
          }
        } catch (err) {
          console.error(err);
          openNotification();
        }
      };
      fetchData(); // Инициализируем вызов данных при монтировании компонента
    },[apiNot]);

    return(
        <>
        {contextHolder}
        <Layout style={{ height: height - 200, borderRadius: borderRadiusLG }}>
          <Layout style={{padding: '24px', borderRadius: borderRadiusLG}}>
            <h1 style={{width: '100%', textAlign: 'center'}}>Персонал проекта SC-Pub</h1>
            <Divider/>
            <Content style={{height: height - 210, overflow: 'auto', marginTop: 10,}} className="online-content">
              {adminData.map((admin: any, index: number) => (
                <AdminCard admin={admin} data={data} key={index} />
              ))}
            </Content>
          </Layout>
        </Layout>
        </>
    );
}

export default OnlinePage;
