import React from "react";
import { Route, Routes } from "react-router-dom";
import RegistrationPage from "./pages/auths/registration";

export const RoutesRouter = () => {
    return(
        <>
        <Routes>
            <Route path="/reg" element={<RegistrationPage />}/>
        </Routes>
        </>
    );
}