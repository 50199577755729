import { Divider, InputNumber, Input, Button, notification, Spin } from "antd";
import { t } from "i18next";
import React, {useCallback, useEffect, useState} from "react";
import { runes } from 'runes2';

import { api } from "../const";

import '../css/profile.css';

const ProfilePage: React.FC = () => {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const [dt, setDt] = useState({
        nickname: '',
        roles: {serverrole: '', siteroles: ['']},
        avatar: '',
        age: '',
        name: '',
        reverse_id: '',
        about: '',
        link: '',
    });
    const [access, setAccess] = useState(false);
    const [revnum, setRevnum] = useState<number | null>(0);
    const [age, setAge] = useState<number | null>(0);
    const [about, setAbout] = useState<string | ''>('');
    const [name, setName] = useState<string | ''>('');
    const [spinning, setSpinning] = useState<boolean>(false);
    const [api2, contextHolder] = notification.useNotification();

    const fetchPlayer = useCallback(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const steamid = urlParams.get('steamid');
        setSpinning(true);
        try{
            if(steamid){
                const res = await api.get('/users/profile/get?steamid='+steamid);
                const ownProfile = await api.get('/auth/');
                
                const opData = ownProfile.data;
                const resdata = res.data;

                if(resdata){
                    setDt(resdata);
                    if(opData.nickname && opData.nickname.includes(resdata.nickname)){
                        setAccess(true);
                        setDt(opData);
                    }
                    setSpinning(false);
                }
            } else if(steamid === "undefined"){
                window.location.href = '/';
            }
        } catch(err) {
            console.log(err);
        }
    },[]);

    const openNotification = (placement: 'bottomRight') => {
        api2.info({
        message: t('change.changed'),
        description:
            t('change.changed')+' about '+about+' '+revnum,
        placement,
        });
    };

    const changeAbout = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAbout(e.target.value);
    };

    const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const sendUpdatedData = async () => {
        try{
            await api.post('/api/profile/updateData', {about, reverse_id: revnum, age, name});
            openNotification('bottomRight');
            fetchPlayer();
        } catch (err){
            console.log(err);
        }
    }

    useEffect(() => {
        fetchPlayer();
    },[]);

    return(
        <>
        {contextHolder}
        <Spin spinning={spinning} tip="Загружаем данные..." fullscreen />
        <div className="profile-background" style={{minHeight: height,}}>
            <div className="profile-block">
                {dt.nickname && dt.roles ? (
                    <div className="main-block" style={(width < 800 && ({width: '100%', margin: '100px 0 0 0', textAlign: 'center'})) && access ? ({}):({width: '30%', marginLeft: '35%'})}>
                        <div className="upper-block">
                            <h1>{dt.nickname}</h1>
                            <img width="40px" height="40px" src={dt.avatar} alt="Avatar image" />
                        </div>
                        {dt.roles ? (<p style={{marginLeft: 'auto', marginRight: 'auto', width: '40%', textAlign: 'center'}}>{t("roles."+dt.roles.serverrole)}</p>):(<p></p>)}
                        <Divider/>
                        {access ? (
                            <div className="content-block-acc" style={width < 800 ? ({display: 'block'}):({})}>
                                <div className="profile-description-acc" style={width < 800 ? ({borderRight: '0', width: '99%'}):({})}>
                                    <p><b>{t('ud.nickname')}: </b>{dt.nickname}</p>
                                    <p><b>{t('ud.reverser-num')}: </b>{dt.reverse_id}</p>
                                    <span>
                                        <p><b>{t('roles.role')}: </b>{dt.roles ? t("roles."+dt.roles.serverrole):''}</p>
                                    </span>
                                    <span>
                                        <p><b>{t('ud.position')}: </b>
                                            {dt.roles.siteroles && dt.roles.siteroles.length > 1 ? (
                                                dt.roles.siteroles.map((role: string, index: any) => (
                                                    <span key={index}>
                                                    {index > 0 && ', '} {t(role)}
                                                    </span>
                                                ))
                                                ) : (
                                                t(`roles.${dt.roles.siteroles}`)
                                            )}
                                        </p>
                                    </span>
                                    <p><b>{t('ud.age')}: </b>{dt.age ? dt.age : t('absent')}</p>
                                    <p><b>{t('ud.name')}: </b>{dt.name ? dt.name : t('absent')}</p>
                                    <p style={{marginBottom: 5}}><b>{t('ud.about-me')}: </b></p>
                                    <p style={{marginTop: 0}}>{dt.about ? dt.about : t('absent')}</p>
                                </div>
                                {width < 800 ? (
                                    <div className="border-pp-horizontal"></div>
                                ):(
                                    <div className="border-pp-vertical"></div>
                                )}
                                <div className="buttons-block" style={width < 800 ? {width: '100%'}:{width:'45%'}}>
                                    <span>
                                        <p style={{fontSize: 15}}>{t('change.reverser-num')}</p>
                                        <InputNumber min={0} max={999} value={revnum} onChange={setRevnum}></InputNumber>
                                    </span>
                                    <span>
                                        <p style={{fontSize: 15, maxWidth: '300px'}}>{t('change.age')}</p>
                                        <InputNumber min={0} max={110} value={age} onChange={setAge}></InputNumber>
                                    </span>
                                    <span>
                                        <p style={{fontSize: 15, maxWidth: '300px'}}>{t('change.about-me')}</p>
                                        <Input value={about} onChange={changeAbout} count={{show: true,max: 100, strategy: (txt) => runes(txt).length, exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),}}></Input>
                                    </span>
                                    <span>
                                        <p style={{fontSize: 15}}>{t('change.name')}</p>
                                        <Input value={name} onChange={changeName} count={{show: true,max: 30, strategy: (txt) => runes(txt).length, exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),}}></Input>
                                    </span>
                                    <Button onClick={sendUpdatedData} style={{marginTop: 5}}>{t('ud.save')}</Button>
                                </div>
                            </div>
                        ):(
                            <div>
                                <div className="profile-description" style={{textAlign: 'center'}}>
                                    <p><b>{t('ud.nickname')}: </b>{dt.nickname}</p>
                                    <p><b>{t('ud.reverser-num')}: </b>{dt.reverse_id ? dt.reverse_id : t('absent')}</p>
                                    <span>
                                        <p><b>{t('roles.role')}: </b>{dt.roles ? t("roles."+dt.roles.serverrole):''}</p>
                                    </span>
                                    <span>
                                        <p><b>{t('ud.position')}: </b>
                                            {dt.roles.siteroles && dt.roles.siteroles.length > 1 ? (
                                                dt.roles.siteroles.map((role: string, index: any) => (
                                                    <span key={index}>
                                                    {index > 0 && ', '} {role}
                                                    </span>
                                                ))
                                                ) : (
                                                dt.roles.siteroles
                                            )}
                                        </p>
                                    </span>
                                    <p><b>{t('ud.about-me')}: </b>{dt.about ? dt.about : t('absent')}</p>
                                </div>
                                <div style={{width: "100%", display: 'flex', flexWrap: 'nowrap', justifyContent: 'center'}}>
                                    <Button href={dt.link}>{t("move-to-profile")+" Steam"}</Button>
                                </div>
                            </div>
                        )}
                    </div>
                ):(
                    <span></span>
                )}
            </div>
        </div>
        </>
    );
}

export default ProfilePage;